<template>
  <div>
    <Header />
    <Login />
    <Footer />
  </div>
</template>

<script>
import Header from "../components/HeaderGeral.vue"
import Login from "../components/Login.vue"
import Footer from "../components/Footer.vue"

export default {
  components: {
    Header,
    Login,
    Footer
  }
}
</script>
