<template>
  <v-row justify="center" align="center" class="margemquadro">
    <v-col align="center" sm="12" md="6" xl="3">
      <v-card>
        <v-card class="formcolor pa-4 rounded-t" flat tile>
          <v-img :src="require('@/assets/logobranco.png')" width="100" />
        </v-card>
        <v-form class="formcolor">
          <v-card-text>
            <v-row class="rounded mb-3 ml-1 mr-1 boxcolor">
              <v-col cols="12">
                <v-text-field
                  v-model="data.login"
                  label="Login"
                  class="padneg"
                  name="login"
                  autofocus
                  required
                />
              </v-col>
            </v-row>
            <v-row class="rounded ml-1 mr-1 boxcolor">
              <v-col cols="12">
                <v-text-field
                  v-model="data.password"
                  label="Senha"
                  class="padneg"
                  required
                  name="password"
                  @click:append="passwordVisibleToggle"
                  :type="passwordVisible ? 'text' : 'password'"
                  :append-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                />
              </v-col>
            </v-row>
            <v-row class="ml-15 mr-15">
              <v-col cols="12">
                <v-btn
                  class="logarButton"
                  @click="handleLogar"
                  type="submit"
                  depressed
                  large
                  block
                >
                  <span>Entrar</span>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="!!errorMessage">
              <v-col cols="12" xl="12">
                <v-alert
                  text
                  outlined
                  class="mt-3"
                  :value="!!errorMessage"
                  type="error"
                  color="white"
                >
                  {{ errorMessage }}
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import svcLogin from "../services/svcLogin";
import { encriptar } from "../services/cripto";

export default {
  data: () => ({
    errorMessage: "",
    passwordVisible: false,
    data: {
      login: "",
      password: "",
    },
  }),
  methods: {
    passwordVisibleToggle() {
      this.passwordVisible = !this.passwordVisible;
    },

    handleLogar(event) {
      event.preventDefault();

      const params = {
        login: this.data.login.trim(),
        password: encriptar(this.data.password.trim()),
      };

      svcLogin
        .login(params)
        .then((resp) => {
          if (resp.status == 200) {
            if (resp.data.valido) {
              localStorage.setItem("di", encriptar(resp.data.id));
              localStorage.setItem("ma", encriptar(resp.data.email));
              localStorage.setItem("no", encriptar(resp.data.nome));
              localStorage.setItem("em", encriptar(resp.data.empresa));
              localStorage.setItem("ad", encriptar(resp.data.administrador));
              localStorage.setItem("im", encriptar(resp.data.imagem));
              if (resp.data.administrador) {
                this.$router.push("/clientes");
              } else {
                this.$router.push("/edit/" + localStorage.getItem("di"));
              }
            } else {
              this.errorMessage = resp.data.mensagem;
            }
          } else {
            alert("Erro acessando o sistema");
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },

  mounted() {
    if (localStorage.getItem("di") != null) {
      localStorage.clear();
      this.$router.push("/");
    }
  },
};
</script>

<style scoped>
.padneg {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: -20px;
}

.boxcolor {
  background-color: rgb(232, 240, 254);
}

.formcolor {
  background-color: #b8774d;
}

.margemquadro {
  margin-top: 200px;
  margin-bottom: 150px;
}
</style>
